
body {
    background: #12232E !important;
}

/*.login-form {*/
/*    flex : 1;*/
/*    !*border-radius: 30px !important;*!*/
/*  }*/
.login-form-forgot {
    float: right !important;

}

.login-form-button {
    width: 100% !important;
}

.logo-image {
    /*width : 350px;*/
    height: 100px
}

.card-avatar {
    height: 100px !important;
    width: 100px !important;
    align-items: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
}
