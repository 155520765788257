.ant-form-item-label > label {
    font-size: 13px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
}

.logout {
    float: right;
}

.iconImage {
    max-width: 20px !important;
    color: white;
    filter: invert(1)
}

.dropdownClass {
    min-width: 350px !important;
}

.no-pend-wf {
    font-size: 30px;
    font-weight: 700;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.initTran {
    font-size: 18px;
    font-weight: 600;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.wfListPage {
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.shoeImage {

}

body {
    /*background: #DFF0D8 !important;*/
}

.logo-image {
    height: 150px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 25px;
    filter: invert(1);
}

.ant-menu-item-icon {
    color: #FF8148 !important;
    font-size: 20px;
}

.ant-menu-item-icon > svg {
    font-size: 22px;
    font-weight: bold;
}
